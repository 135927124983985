/**
 * Main javascript file that powers this website
 */

 /**
  * Dependencies
  */
 import { gsap } from "gsap"; // gsap for animations
 import { SplitText } from "gsap/SplitText";
 import { ScrollToPlugin } from "gsap/ScrollToPlugin";
 import { Draggable } from "gsap/Draggable";
 import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
 import { ScrollTrigger } from "gsap/ScrollTrigger";
 import $ from 'jquery';
 window.$ = window.jQuery = $;
 import "slick-carousel";

 // Be sure to register gsap plugins so webpack picks it up
 gsap.registerPlugin(ScrollTrigger);
 gsap.registerPlugin(Draggable);
 gsap.registerPlugin(DrawSVGPlugin);
 gsap.registerPlugin(ScrollToPlugin);

/**
 * AlulaMP Site Base Class
 *
 */
class AlulaMP_Base {

    /**
     * Constructor
     */
    constructor() {

        // Load interactions to page
        this.buildPage();
    }

    /**
     * Screen size detector helpers
     */
    screenSmall() {
        return window.innerWidth < 1000;
    }
    screenLarge() {
        return window.innerWidth > 999;
    }

    /**
     * RTL detector
     */
    isRTL() {
        return document.documentElement.lang == 'ar-sa';
    }

    /**
     * Check if selector exists
     */
    doesElementExist(selector) {
        return document.querySelector(selector) !== null;
    }

    /**
     * Page Loader
     */
    buildPage() {

        // This block pertains to the homepage layout
        // load each section
        this.nav();
        this.hero();
        this.quote();
        this.titleScrolls();
        this.featuredContentImages(".amp-section3");
        this.featuredContentStats(".amp-section4");
        this.headerImages(".amp-section5");
        this.cards(".amp-section6");
        this.text(".amp-section6");
        this.maps(".amp-section8");
        this.cards(".amp-section9");
        this.beforeafter();
        this.faqs(".amp-section12");
        this.featureSingleImage(".amp-section13");
        this.headerImages(".amp-section15 .img-block");
        this.fullWidthContentSlider(".amp-section-carousel");
        this.relatedContent();
        this.promo();
        this.photoGallery();

        /** These are globals  */
        this.lightbox();
        this.sliders();
        this.scrollers();

        this.announcement();
        this.announcementLB();

    }


    /**
     * Load Nav
     */
    nav() {

    if (document.getElementById('full-nav') !== null) {
        let tl_nav = gsap.timeline();
        tl_nav.paused( true );
        tl_nav.fromTo("#full-nav", { translateY: "-100%", transformOrigin: "0% 0 0", opacity: 0}, { duration: 1, ease: "power.in", absolute: 1, translateY: 0, opacity: 1 });
        document.getElementById("navBtn").addEventListener("click", function(e){
            document.querySelector("html").style.overflow = 'hidden';
            tl_nav.play();
        });
        document.getElementById("navClose").addEventListener("click", function(e){
            document.querySelector("html").style.overflow = '';
            tl_nav.reverse();
        });

        // the y value here is affected by the height of the top announcment if it's present
        // we should get the offsetheight of the announcement if it is present but that's complicated
        // by the fact it can be closed by the user
        var actionNav = gsap.to('nav', {y:'-=164', duration:0.5, ease:'power2.in', paused:true}),
            nav = document.getElementById("menu-nav");

        ScrollTrigger.create({
            trigger: "nav",
            start: "0 top",
            end: 99999,
            onUpdate: ({progress, direction, isActive}) => {
                //console.log(progress);
                if (direction == -1) {
                    actionNav.reverse();
                    nav.classList = "is-scrolled" //progress > 0 && direction == -1 ?  : "";
                } if (direction == 1 ) {
                    actionNav.play()
                } else if (direction == 1 && isActive == true) {
                    actionNav.play()
                }

                if (window.scrollY == 0) nav.classList = "";
                //console.log(nav.classList);
            }
        });
    }

        document.querySelectorAll('#full-nav .card-slider a[href^="#"]').forEach(function(element, index){
              element.addEventListener("click", function(e){
                  e.preventDefault();
                  document.querySelector("html").style.overflow = '';
                  var section = element.getAttribute('href').replace("#",""), section_scroll = false;
                  if (section) {
                      switch(section) {
                          case "cultural-landscape":
                            section_scroll = "section3";
                            break;
                          case "masterplan":
                            section_scroll = "section4";
                            break;
                          case "history":
                            section_scroll = "section6";
                            break;
                          case "map":
                            section_scroll = "section8";
                            break;
                          case "faqs":
                            section_scroll = "section12";
                            break;
                          case "livingmuseum":
                            section_scroll = "section13";
                            break;
                          case "stayconnected":
                            section_scroll = "section14";
                            break;
                        }
                  }
                  if (section_scroll){
                      gsap.to(window, { duration: .4, scrollTo: { y: ".amp-"+section_scroll } } );
                  }
                  else {
                      gsap.to(window, { duration: .4, scrollTo: { y: "#"+section } } );
                  }
                  tl_nav.reverse();
              });
        });

    }

    /**
     * Open Lightbox links
     */
    lightbox() {
        var links = document.querySelectorAll(".open-lightbox");
        for(var i=0;i<links.length;i++){
            var link = links[i];
            link.addEventListener("click", function(e){
                // tl.paused(!tl.paused());
                if (typeof window.AlulaMP_Base.mobility !== 'undefined') window.AlulaMP_Base.mobility.pause();
                e.target.disabled = true;
                setTimeout(function(){ e.target.disabled = false; }.bind(e), 750);
                //console.log(e.target);
                gsap.fromTo("." + e.target.dataset.lightbox, { translateY: "-100%", transformOrigin: "0% 0 0", top: 0, opacity: 0}, { duration: 1, ease: "power.in", absolute: 1, translateY: 0, opacity: 1, pointerEvents: 'auto' });
                document.querySelector("html").style.overflow = 'hidden';
            });
        }
        var links = document.querySelectorAll(".close-lightbox");
        for(var i=0;i<links.length;i++){
            var link = links[i];
            link.addEventListener("click", function(e){
                if (typeof window.AlulaMP_Base.mobility !== 'undefined') window.AlulaMP_Base.mobility.play();

                // If any videos lets pause them
                if (e.target.parentNode.closest(".lightbox").querySelector("iframe")) {
                    let src = e.target.parentNode.closest(".lightbox").querySelector("iframe").src;
                    e.target.parentNode.closest(".lightbox").querySelector("iframe").src = '';
                    e.target.parentNode.closest(".lightbox").querySelector("iframe").src = src;
                }

                gsap.to(e.target.parentNode.closest(".lightbox"), { translateY: "-100%", transformOrigin: "0% 0 0", duration: 1, ease: "power.in", absolute: 1, top: 0, opacity: 0, pointerEvents: 'none'});
                document.querySelector("html").style.overflow = '';
            });
        }
    }

    /**
     * Setup Image Sliders
     */
    sliders() {

        var globalThis = this;
        document.querySelectorAll('.slider .controls button').forEach(function(element, index){
            element.addEventListener("click", function(e){
                    e.target.disabled = true;
                    setTimeout(function(){ e.target.disabled = false; }.bind(e), 750);
                    var slider_items = e.target.parentNode.closest(".slider").children[0].children, active_slide =0, slide_to=1;

                    for(var i=0; i<slider_items.length; i++){
                        var slider = slider_items[i];
                        //console.log('slider', slider.style.display);
                        if (slider.style.display == "inline-block") {
                            active_slide = i;
                            slider.style.display = "none";
                        }
                    }

                    if (e.target.classList.contains("prev")) {
                        // move slider to previous
                        if (active_slide === 0) {
                            slide_to = slider_items.length - 1;
                        }
                        else {
                            slide_to = active_slide - 1;
                        }
                        for(var i=0; i<slider_items.length; i++){
                            var slider = slider_items[i];
                            if (i === slide_to) {
                                //slider.style.display = "inline-block";
                                gsap.fromTo(slider, {opacity: 0}, { duration: .5, opacity: 1, display: "inline-block", ease: "power1.inOut"});
                            }
                        }

                    }
                    else {
                        //move to next
                        if (active_slide === (slider_items.length - 1)) {
                            slide_to = 0;
                        }
                        else {
                            slide_to = active_slide + 1;
                        }
                        for(var i=0; i<slider_items.length; i++){
                            var slider = slider_items[i];
                            if (i === slide_to) {
                                gsap.fromTo(slider, {opacity: 0}, { duration: .5, opacity: 1, display: "inline-block", ease: "power1.inOut"});
                            }
                        }
                    }
            });
        })
    }

    /**
     * Setup Div Scroll Icons
     */
    scrollers() {

        var p = this;

        var checkScrollArea = function(e,element){
            var slider = e.target;
                var prevButton = e.target.parentNode.querySelector('.prev');
                var nextButton = e.target.parentNode.querySelector('.next');
                var scrollAmt = 230, scrollContainerHeight, scrollOffset;
                if (
                    !element.classList.contains("horizontal") &&
                    (
                        (element.classList.contains("vertical")  && (window.innerWidth > 999))
                        ||
                        (!element.classList.contains("vertical")  && !(window.innerWidth > 999))
                    )
                )
                {
                    var currentScrollPos = slider.scrollTop;
                    scrollContainerHeight = slider.scrollHeight;
                    scrollOffset = slider.offsetHeight;

                    var newScrollPos = currentScrollPos;
                    prevButton.style.opacity = (newScrollPos < 1) ? 0 : 1;
                    nextButton.style.opacity = (newScrollPos >= (scrollContainerHeight - scrollOffset)) ? 0 : 1;
                }
                else {
                    // horizontal slider
                    var currentScrollPos = slider.scrollLeft;
                    scrollContainerHeight = slider.scrollWidth;
                    scrollOffset = slider.offsetWidth;

                    var newScrollPos = currentScrollPos;
                    prevButton.style.opacity = (parseInt(newScrollPos) < 1 && !p.isRTL()) || (parseInt(newScrollPos) > -1 && p.isRTL()) ? 0 : 1;
                    nextButton.style.opacity = (parseInt(newScrollPos) >= (scrollContainerHeight - scrollOffset) && !p.isRTL() )
                                                ||
                                                ((parseInt(newScrollPos) * -1) >= (scrollContainerHeight - scrollOffset) && p.isRTL())
                                                ? 0 : 1;
                }
        }

        var checkScroll = function(e,element,a){

                var slider = e.target.parentNode.parentNode.querySelector('.slider-scroller') || e.target.parentNode.parentNode;
                var prevButton = e.target.parentNode.querySelector('.prev');
                var nextButton = e.target.parentNode.querySelector('.next');
                var scrollAmt = 230, scrollContainerHeight, scrollOffset;
                if (
                    !element.classList.contains("horizontal") &&
                    (
                        (element.classList.contains("vertical")  && (window.innerWidth > 999))
                        ||
                        (!element.classList.contains("vertical")  && !(window.innerWidth > 999))
                    )
                )
                {
                    var currentScrollPos = slider.scrollTop;
                    scrollContainerHeight = slider.scrollHeight;
                    scrollOffset = slider.offsetHeight;
                    var newScrollPos = e.target.classList.contains("prev") ? (currentScrollPos - scrollAmt) : (currentScrollPos + scrollAmt);
                    if (newScrollPos < 1 ) newScrollPos = 0;
                    slider.scrollTo({
                        top: newScrollPos,
                        behavior: 'smooth'
                    });
                }
                else {
                    // horizontal slider
                    scrollAmt = p.isRTL() ? -325 : 325;
                    var currentScrollPos = slider.scrollLeft;
                    scrollContainerHeight = slider.scrollWidth;
                    scrollOffset = slider.offsetWidth;
                    var newScrollPos = e.target.classList.contains("prev") ? (currentScrollPos - scrollAmt) : (currentScrollPos + scrollAmt);
                    if (newScrollPos < 1 && !p.isRTL() ) newScrollPos = 0;
                    //console.log('slide horizontal');
                    //console.log(slider.scrollLeft, slider.scrollWidth, scrollOffset, newScrollPos)
                    slider.scrollTo({
                        left: newScrollPos,
                        behavior: 'smooth'
                    });
                }
                prevButton.style.opacity = (parseInt(newScrollPos) < 1 && !p.isRTL()) || (parseInt(newScrollPos) > -1 && p.isRTL()) ? 0 : 1;
                nextButton.style.opacity = (parseInt(newScrollPos) >= (scrollContainerHeight - scrollOffset) && !p.isRTL() )
                                            ||
                                            ((parseInt(newScrollPos) * -1) >= (scrollContainerHeight - scrollOffset) && p.isRTL())
                                            ? 0 : 1;
        }

        document.querySelectorAll('.controls.scroller button').forEach(function(element, index){
            if (element.classList.contains("prev")) {
                element.style.opacity = 0;
            }

            element.addEventListener("click", function(e){
                checkScroll(e, element);
            });
        })

        document.querySelectorAll('.slider-scroller').forEach(function(element, index){
            element.addEventListener("scroll", function(e){
                checkScrollArea(e, element.parentNode.querySelector('.next'));
            });
        })

    }

    /**
     * Setup header image section animation
     */
    headerImages(section){

        // Lets disable the image fade in for now.  Turn on back later.
        /*
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: section + " > img",
                start: "-20% 100%",
                scrub: true
            }
        });

        tl.from(section + " img", {opacity: 0, duration: 1, ease: "power1.inOut"});
        */
    }

    /**
     * Load Hero Section
     */
    hero() {
        var section = ".amp-section1 .animated-copy";

        if (! this.doesElementExist(section)) return;

        let tl = gsap.timeline({});

        /** animated text */
        tl.to(section + " .bg-wrap", { opacity: 1, duration: 2, delay: 1});
        tl.from(section + " .line", { width: 0, opacity: 0, duration: 1, ease: "power1.inOut", transformOrigin: "center center"}, "-=.5");
        //  .from(section + " h1", { duration: 0.75, y: "50%", opacity: 0 }, "text")
        //  .from(section + " .subhead-h1", { duration: 0.75, y: "-50%", opacity: 0 }, "text")
        //  .from(section + " .button", { duration: 0.75, y: "-50%", opacity: 0 }, "text")
        //  .to(section + " .button", {opacity: 1, duration: 1});

        /** lets add a scrub feature to the hero image */
        if (this.screenLarge() && this.doesElementExist(".scrollOverHero")) {
            const tl_hero = gsap.timeline({scrollTrigger:{
                trigger: section,
                scrub: 2,
                pin:true,
                start:"top top",
                end:"+100%"
            }});
        }

    }

    /**
     * Quote + Image Area
     */
    quote() {

        if (! this.doesElementExist(".amp-section2")) return;

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".amp-section2",
                start: "top 70%",
                toggleActions: "play pause restart pause",
                end: "+=50%",
                scrub: 1
            }
        });
        var split = new SplitText(".amp-section2 .quote blockquote span", {type: "words"});

        tl.from(split.words, {x: -5, duration: .3, stagger: .01, opacity: 0, ease: "power3"})
          .from(".amp-section2 .mbs img", {x: "-20%", opacity: 0, duration: 1}, "-=.5")
          .from(".amp-section2 .mbs .title", {y: 50, opacity: 0, scale: 0, duration: .5}, "-=.5")

    }
    /**
     * Featured Content Area + images
     */
    featuredContentImages(section) {

        if (! this.doesElementExist(section)) return;

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: section + " .featured-wrap",
                start: "top center",
                toggleActions: "play pause restart pause"
            }
        });

        tl.from(section + " .col-narrow", {opacity: 0, duration: .5})
          .from(section + " .col-wide", {opacity: 0, duration: 1}, "<+.3")
          .from(section + " .col-center li", {x: 10, y: 0, stagger: .2, duration: .4, opacity: 0, ease: "power1.in" }, "<")
          .to(section + " .controls", {opacity: 1, duration: 1});
    }
    /**
     * Featured Content Area + stats
     */
    featuredContentStats(section) {

        if (! this.doesElementExist(section)) return;

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: section + " .featured-wrap",
                start: "top 50%",
                toggleActions: "play pause restart pause"
            }
        });


        //var split = new SplitText(section + " span", {type: "chars,words,lines"});

        tl.from(section + " .col-narrow", {opacity: 0, duration: .5})
        .from(section + " .col-wide", {opacity: 0, duration: 1}, "<+.3")
        .from(section + " .col-center li", {x: 5, y: 0, stagger: .3, duration: .5, opacity: 0 }, "<-.5")
        .to(section + " .controls", {opacity: 1, duration: 1});
    }

    /**
     * Single Image Feature
     */
    featureSingleImage(section) {

        if (! this.doesElementExist(section)) return;

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: section + " .single-wrap",
                start: "top center",
                toggleActions: "play pause restart pause"
            }
        });

        tl.from(section + " .text .inner", { duration: .4, opacity: 0, ease: "power1.in" })
            .from(section + " .image-wrap", {opacity: 0, duration: .5}, "-=.5");
    }

    /**
     * Scrolling Titles
     */
    titleScrolls() {
        gsap.utils.toArray(".title-scroll").forEach(layer => {
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: layer,
                start: "-=100% 90%",
                toggleActions: "play pause reverse pause",
                // markers: true,
                scrub: true,
                paused: true
            }
        });
        var directionFix1 = this.isRTL() ? "30%" : "-30%";
        var directionFix2 = this.isRTL() ? "-30%" : "30%";

            ScrollTrigger.matchMedia({
                // desktop
                "(min-width: 800px)": function() {
                    tl.from(layer.querySelector("h2"), {x: directionFix1, opacity: 0, duration: 1})
                    .from(layer.querySelector("p"), {x: directionFix2, opacity: 0, duration: 1}, "<");
                },

                // mobile
                "(max-width: 799px)": function() {
                    tl.from(layer.querySelector("h2"), {x: directionFix1, duration: 1})
                    .from(layer.querySelector("p"), {x: directionFix2, duration: 1}, "<");
                }
            });
        });

    }


    /**
     * FAQS Area
     */
    faqs(section) {

        if (! this.doesElementExist(section)) return;

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: section,
                start: "top center",
                toggleActions: "play none none none",
            }
        });

        var
        toggleItems,
        items;
        toggleItems     = document.querySelectorAll('.accordion .item-wrap');
        toggleItems     = Array.prototype.slice.call(toggleItems);
        items           = document.querySelectorAll('.accordion .item');
        items           = Array.prototype.slice.call(items);

        tl.set(items, {visibility:'visible'});
        tl.staggerFrom(items, 0.4, {opacity:0, ease:"power1.out", x: -10}, .2);

        toggleItems.forEach(function(element, index) {
            element.addEventListener('click', function() {
                var parent = this.parentNode.closest(".item");
                var content = parent.children[0].children[1];
                if(!parent.classList.contains('is-open')) {
                    parent.classList.add('is-open');

                } else {
                    parent.classList.remove('is-open');
                }
            },
            false);
        });

    }


    /**
     * Text Block Fade
     */
    text(section) {

        if (! this.doesElementExist(section)) return;

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: section,
                start: "top center",
                toggleActions: "play none restart none"
            }
        });
        var split = new SplitText(section + " .text-block h3", {type: "words"});

        tl.from(split.words, {x: 10, y: 4, duration: 2, autoAlpha: 0, opacity: 0, ease: "power3", stagger: 0.01})

    }

    /**
     * Before After Slider
     */
    beforeafter() {

        if (! this.doesElementExist(".beforeafter-slider")) return;

        var slider = document.querySelector(".beforeafter-slider"),
        sliderChanger = document.getElementById("before-after-toggle"),
        preventSnap = false;

        var p = this;

        var directionFix1 = this.isRTL() ? -132 : 132;
        var directionFix2 = this.isRTL() ? -110 : 110;

        Draggable.create("#beforeafter-slider-handle", {
            type:'x',
            bounds: slider,
            liveSnap:function(endValue) {
                var direction = this.getDirection("velocity");
                return Math.round(endValue / 128) * 128;
            },
            // onDragStart: function() {
            //     console.log(this, this.x, this.target.endX);
            //     slider.classList = ((p.isRTL() && this.x < -110) || (!p.isRTL() && this.x < 40)) ? "beforeafter-slider end" : "beforeafter-slider";
            //     if ( (!p.isRTL() && this.x < 45) || (p.isRTL() && this.x == 0) ) {
            //         gsap.to(this.target, { translateX: directionFix1, duration: .3 });
            //         gsap.to(".amp-section10 .before-after-"+sliderChanger.value+" .before", { display: "none", duration: 2, opacity: 0 });
            //         gsap.fromTo(".amp-section10 .before-after-"+sliderChanger.value+" .after", { opacity: 0 }, { display: "block", opacity: 1, duration: 2 }, "-=.4");
            //         document.getElementById("beforeafter-slider-handle").innerHTML = 'The Vision';
            //         document.querySelector(".beforeafter-slider #ba-slide-right-icon").style.display = 'none';
            //         document.querySelector(".beforeafter-slider #ba-slide-left-icon").style.display = 'inline';
            //     }
            //     else {
            //         gsap.to(this.target, { translateX: 0, duration: .3 });
            //         gsap.to(".amp-section10 .before-after-"+sliderChanger.value+" .after", { display: "none", duration: 2, opacity: 0 });
            //         gsap.fromTo(".amp-section10 .before-after-"+sliderChanger.value+" .before", { opacity: 0 }, { display: "block", opacity: 1, duration: 2 }, "-=.4");
            //         document.getElementById("beforeafter-slider-handle").innerHTML = 'Now';
            //         document.querySelector(".beforeafter-slider #ba-slide-right-icon").style.display = 'inline';
            //         document.querySelector(".beforeafter-slider #ba-slide-left-icon").style.display = 'none';
            //     }
            // },
            onDrag: function(){ preventSnap = true; },
            onDragEnd:function() {
                preventSnap = true;
                // gsap.to(".beforeafter-slider p", { duration: .1,
                //         right: p.isRTL() ? null : (this.endX - 40) < 1 ? 43 : this.endX - 40,
                //         left: !p.isRTL() ? null : (this.endX - 40) < 1 ? 43 : this.endX - 40 });
                // gsap.to(".beforeafter-slider p", { duration: .1, right: (this.endX - 40) < 1 ? 43 : this.endX - 40 });

                slider.classList = (this.endX > directionFix2) ? "beforeafter-slider end" : "beforeafter-slider";
                if ((p.isRTL() && this.endX < directionFix2) ||  (!p.isRTL() && this.endX > directionFix2) ) {
                    gsap.to(".amp-section10 .before-after-"+sliderChanger.value+" .before", { display: "none", duration: 2, opacity: 0 });
                    gsap.fromTo(".amp-section10 .before-after-"+sliderChanger.value+" .after", { opacity: 0 }, { display: "block", opacity: 1, duration: 2 }, "-=.4");
                    document.getElementById("beforeafter-slider-handle").querySelector('.before').style.display = 'none';
                    document.getElementById("beforeafter-slider-handle").querySelector('.after').style.display = 'inline';
                    document.querySelector(".beforeafter-slider #ba-slide-right-icon").style.display = 'none';
                    document.querySelector(".beforeafter-slider #ba-slide-left-icon").style.display = 'inline';
                }
                else {
                    gsap.to(".amp-section10 .before-after-"+sliderChanger.value+" .after", { display: "none", duration: 2, opacity: 0 });
                    gsap.fromTo(".amp-section10 .before-after-"+sliderChanger.value+" .before", { opacity: 0 }, { display: "block", opacity: 1, duration: 2 }, "-=.4");
                    document.getElementById("beforeafter-slider-handle").querySelector('.before').style.display = 'inline';
                    document.getElementById("beforeafter-slider-handle").querySelector('.after').style.display = 'none';
                    document.querySelector(".beforeafter-slider #ba-slide-right-icon").style.display = 'inline';
                    document.querySelector(".beforeafter-slider #ba-slide-left-icon").style.display = 'none';
                }
            }
          });

        sliderChanger.addEventListener("change", function(e){
            document.querySelectorAll(".amp-section10 .imgs-wrap").forEach(function(element, index){
                element.style.display = 'none';
            });
            var mainSlider = document.getElementById("before-after-toggle"), mainSliderContainer = document.querySelector(".beforeafter-slider");
            document.querySelector(".amp-section10 .before-after-" + mainSlider.value).style.display = "block";
            mainSliderContainer.classList = "beforeafter-slider";

            gsap.to("#beforeafter-slider-handle", { x: 0 });
            //gsap.to(".beforeafter-slider p", { duration: .1, right: 43} );
            gsap.to(".amp-section10 .before-after-"+mainSlider.value+" .after", { display: "none", duration: 2, opacity: 0 });
            gsap.fromTo(".amp-section10 .before-after-"+mainSlider.value+" .before", { opacity: 0 }, { display: "block", opacity: 1, duration: 2 }, "-=.4");
            document.getElementById("beforeafter-slider-handle").querySelector('.before').style.display = 'inline';
            document.getElementById("beforeafter-slider-handle").querySelector('.after').style.display = 'none';
            document.querySelector(".beforeafter-slider #ba-slide-right-icon").style.display = 'inline';
            document.querySelector(".beforeafter-slider #ba-slide-left-icon").style.display = 'none';

        });

    }

    /**
     * Card Slider
     */
    cards(section) {

        if (! this.doesElementExist(section)) return;

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: section,
                start: "top center",
                toggleActions: "play resume restart resume",
            }
        });

        var items = document.querySelectorAll(section + " .card");

        tl.set(items, {visibility:'visible'});
        tl.staggerFrom(items, 0.9,{opacity:0, x:10, ease:"elastic"}, 0.3)
    }

    /**
     * Maps Area
     */
    maps(section) {

        if (! this.doesElementExist(section)) return;

        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: section+ " .map-overview",
                start: "top center",
                //end: "+=200%",
                toggleActions: "play pause restart pause"
            }
        });

        tl.fromTo(section + " .map-overview .mapcirclelarge", { scale: 1, opacity: 1, stagger: .4,  transformOrigin:"center center", }, { scale: 6, opacity: .5, duration:1.5, repeat: -1, yoyo: true}, "<")
        .from(section + " .map-overview .pathoutline", { opacity: 0, duration:3, stagger: 0.7, drawSVG: 0 }, "-=1");


        let tl_masterplan = gsap.timeline({
            paused: true,
            scrollTrigger: {
                trigger: section + " .map-masterplan",
                start: "top center",
                //end: "+=200%",
                toggleActions: "play pause play pause",
                paused: true
            }
        });
        tl_masterplan.paused(true);

        tl_masterplan.fromTo(section + " .map-masterplan .mapcircle", { scale: .5, opacity: 1, transformOrigin:"center center", }, { scale: 1.3, opacity: .5, duration:1.5, repeat: -1, stagger: .01, yoyo: true})
                    .fromTo(section + " .map-masterplan .mapcirclelarge", { scale: .5, opacity: 1, stagger: .4,  transformOrigin:"center center", }, { scale: 2, opacity: .5, duration:1.5, repeat: -1, yoyo: true}, "<");


        const MOBILITY_MAP_PAUSE = 2;
        let tl_mobility = gsap.timeline({
            repeatDelay: 2,
            repeat: -1,
            yoyo: false,
            paused: true,
            scrollTrigger: {
                trigger: section + " .map-mobility",
                start: "top center",
                //end: "+=200%",
                toggleActions: "play pause play pause",
                paused: true
            }
        });
        window.AlulaMP_Base.mobility = tl_mobility;
        tl_mobility.paused(true);
        tl_mobility
        .set(section + " .map-mobility .legend-animation li", { display: "none", opacity: 0}, "+=.5")
        .to(section + " .map-mobility .legend-animation li.scenicroads", { duration: .5, opacity: 1, display: 'flex'})
        .fromTo(section + " .map-mobility #animate-MOBILITY_SCENICROAD1 path, " + section + " .map-mobility #animate-MOBILITY_SCENICROAD1 line", { drawSVG:0, opacity: 1}, { duration:2, opacity: 1, stagger: 1, drawSVG:"-100%", transformOrigin:"center bottom"  })
        .fromTo(section + " .map-mobility #animate-MOBILITY_SCENICROAD2 path, " + section + " .map-mobility #animate-MOBILITY_SCENICROAD2 line", { drawSVG:0, opacity: 1}, { duration:2, opacity: 1, stagger: 1, drawSVG:"100%", transformOrigin:"center bottom"  })
        .fromTo(section + " .map-mobility #animate-MOBILITY_SCENICROAD3 path, " + section + " .map-mobility #animate-MOBILITY_SCENICROAD3 line", { drawSVG:0, opacity: 1}, { duration:2, opacity: 1, stagger: 1, drawSVG:"100%", transformOrigin:"center bottom"  })

        .set(section + " .map-mobility .legend-animation li", { display: "none", opacity: 0}, "+=2")
        .to(section + " .map-mobility .legend-animation li.aerial", { duration: .5, opacity: 1, display: 'flex'})
        .fromTo(section + " .map-mobility #animate-AERIALTRAILS path, " + section + " .map-mobility #animate-AERIALTRAILS line", { drawSVG: 0, opacity: 1 }, { duration:1, opacity: 1, stagger: .01, drawSVG:"-100%", transformOrigin:"center center"})

        .set(section + " .map-mobility .legend-animation li", { display: "none", opacity: 0}, "+=2")
        .to(section + " .map-mobility .legend-animation li.bike", { duration: .5, opacity: 1, display: 'flex'})
        .fromTo(section + " .map-mobility #animate-BIKETRAILS path, " + section + " .map-mobility #animate-BIKETRAILS line", { drawSVG: 0, opacity: 1 }, { duration:1, opacity: 1, stagger: .01, drawSVG:"-100%", transformOrigin:"center center"})

        .set(section + " .map-mobility .legend-animation li", { display: "none", opacity: 0}, "+=2")
        .to(section + " .map-mobility .legend-animation li.hejaz", { duration: .5, opacity: 1, display: 'flex'})
        .fromTo(section + " .map-mobility #animate-HEJAZRAILWAYBIKETRAILS path, " + section + " .map-mobility #animate-HEJAZRAILWAYBIKETRAILS line", { drawSVG: 0, opacity: 1 }, { duration:1, opacity: 1, drawSVG:"-100%", transformOrigin:"center center"})

        .set(section + " .map-mobility .legend-animation li", { display: "none", opacity: 0}, "+=2")
        .to(section + " .map-mobility .legend-animation li.horse", { duration: .5, opacity: 1, display: 'flex'})
        .fromTo(section + " .map-mobility #animate-HORSETRAILS path, " + section + " .map-mobility #animate-HORSETRAILS line", { drawSVG: 0, opacity: 1 }, { duration:1, opacity: 1, stagger: .01, drawSVG:"-100%", transformOrigin:"center center"})

        .set(section + " .map-mobility .legend-animation li", { display: "none", opacity: 0}, "+=2")
        .to(section + " .map-mobility .legend-animation li.tram-exp", { duration: .5, opacity: 1, display: 'flex'})
        .fromTo(section + " .map-mobility #animate-MOBILILTY_TRAM path, " + section + " .map-mobility #animate-MOBILILTY_TRAM line", { drawSVG: 0, opacity: 1 }, { duration:2, opacity: 1, stagger: .05, drawSVG:"-100%", transformOrigin:"center center"});
        // .fromTo(section + " .map-mobility .groupoutline path", { drawSVG: 0 }, { duration:2, stagger: .05, drawSVG: "100%", repeat: 3, yoyo: false, })
        // .fromTo(section + " .map-mobility .groupoutline path", { drawSVG: 0 }, { duration:2, stagger: .05, drawSVG: "100%", repeat: 3, yoyo: false, })

        tl_masterplan.scrollTrigger.disable(true);
        tl_mobility.scrollTrigger.disable(true);
        // Add map interactions

        var directionFix1 = this.isRTL() ? "100%" : "-100%";


        // Map close legend
        var mapclose = document.querySelectorAll('.map-wrap .legend .close');
        if (mapclose) {
            mapclose.forEach(function(element, index){
                element.addEventListener("click", function(e){
                    var t = e.target.parentNode.closest(".map-wrap");
                    gsap.to(t.querySelector(".legend"), { opacity: 0, display: "none", duration: 1 });
                    t.querySelector(".info").classList = 'info';
                });
            });
        }
        // Map info button (show legend)
        var mapInfo = document.querySelectorAll('.map-wrap .info');
        if (mapInfo) {
            mapInfo.forEach(function(element, index){
                element.addEventListener("click", function(e){
                    var t = e.target.parentNode.closest(".map-wrap");
                    gsap.to(t.querySelector(".legend"), { opacity: 1, display: "block", duration: 1 });
                    t.querySelector(".info").classList = 'info is-open';
                });
            });
        }
        document.getElementById("map_toggle_overview").addEventListener("click", function(e){
            e.preventDefault();
            tl_masterplan.paused(true);
            tl_mobility.paused(true);
            tl_masterplan.scrollTrigger.disable(true);
            tl_mobility.scrollTrigger.disable(true);

            e.target.disabled = true;
            setTimeout(function(){ e.target.disabled = false; }.bind(e), 750);
            document.querySelectorAll(".amp-section8 .toolbar .toggle").forEach(function(element, index){
                element.classList = "toggle";
            });
            document.querySelectorAll(".amp-section8 .map-wrap").forEach(function(element, index){
                element.style.display = "none";
            });
                document.getElementById("map_toggle_overview").classList = "toggle is-active";
                gsap.fromTo(".amp-section8 .map-overview", { opacity: 0 }, { opacity: 1, duration: .7, display: 'block' });
                tl.paused(false);
                tl.scrollTrigger.enable();
        });
        document.querySelectorAll(".showmasterplanmap").forEach(function(element, index){
            element.addEventListener("click", function(e){
                e.preventDefault();
                tl.paused(true);
                tl_mobility.paused(true);
                tl.scrollTrigger.disable(true);
                tl_mobility.scrollTrigger.disable(true);
                e.target.disabled = true;
                setTimeout(function(){ e.target.disabled = false; }.bind(e), 750);
                document.querySelectorAll(".amp-section8 .map-wrap").forEach(function(element, index){
                    element.style.display = "none";
                });
                document.querySelectorAll(".amp-section8 .toolbar .toggle").forEach(function(element, index){
                    element.classList = "toggle";
                });
                document.getElementById("map_toggle_masterplan").classList = "toggle is-active";
                gsap.fromTo(".amp-section8 .map-masterplan", { opacity: 0 }, { opacity: 1, duration: .7, display: 'block' });
                tl_masterplan.paused(false);
                tl_masterplan.scrollTrigger.enable();
            });
        });
        document.querySelectorAll(".showmobilitymap").forEach(function(element, index){
            element.addEventListener("click", function(e){
                e.preventDefault();
                tl.paused(true);
                tl_masterplan.paused(true);
                tl.scrollTrigger.disable(true);
                tl_masterplan.scrollTrigger.disable(true);
                e.target.disabled = true;
                setTimeout(function(){ e.target.disabled = false; }.bind(e), 750);
                document.querySelectorAll(".amp-section8 .map-wrap").forEach(function(element, index){
                    element.style.display = "none";
                });
                document.querySelectorAll(".amp-section8 .toolbar .toggle").forEach(function(element, index){
                    element.classList = "toggle";
                });
                document.getElementById("map_toggle_mobility").classList = "toggle is-active";
                gsap.fromTo(".amp-section8 .map-mobility", { opacity: 0 }, { opacity: 1, duration: 1, display: 'block' });
                tl_mobility.paused(false);
                tl_mobility.scrollTrigger.enable();
            });
        });


    }

    /**
     * Scrolling Titles
     */
    textCounters(section) {

        // Do nothing for now.

    }

    /**
     * Full Content Slider Carousel
     */
    fullWidthContentSlider(section) {

        document.querySelectorAll(section + ' .controls button').forEach(function(element, index){
            element.addEventListener("click", function(e){
                    e.target.disabled = true;
                    setTimeout(function(){ e.target.disabled = false; }.bind(e), 750);
                    var slider_items = e.target.parentNode.parentNode.children[1].children, active_slide =0, slide_to=1;
                    for(var i=0; i<slider_items.length; i++){
                        var slider = slider_items[i];
                        if (slider.style.display == '' || slider.style.display == "flex") {
                            active_slide = i;
                            slider.style.display = "none";
                        }
                    }

                    if (e.target.classList.contains("prev")) {
                        // move slider to previous
                        if (active_slide === 0) {
                            slide_to = slider_items.length - 1;
                        }
                        else {
                            slide_to = active_slide - 1;
                        }
                        for(var i=0; i<slider_items.length; i++){
                            var slider = slider_items[i];
                            if (i === slide_to) {
                                //slider.style.display = "flex";
                                gsap.fromTo(slider, {opacity: 0}, { duration: .5, opacity: 1, display: "flex", ease: "power1.inOut"});
                            }
                        }

                    }
                    else {
                        //move to next
                        if (active_slide === (slider_items.length - 1)) {
                            slide_to = 0;
                        }
                        else {
                            slide_to = active_slide + 1;
                        }
                        for(var i=0; i<slider_items.length; i++){
                            var slider = slider_items[i];
                            if (i === slide_to) {
                                gsap.fromTo(slider, {opacity: 0}, { duration: .5, opacity: 1, display: "flex", ease: "power1.inOut"});
                            }
                        }
                    }
            });
        })
    }

    relatedContent() {
        document.querySelectorAll('.component--related-content').forEach(function(element, index){
            element.querySelectorAll('.related-content-item').forEach(function(item, index){
                let tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: item,
                        start: "top bottom-=200",
                        toggleActions: "play pause restart pause",
                    }
                });
                tl.fromTo(item, {opacity: 0, y: 50}, {opacity: 1, y: 0});
            });
        });
    }

    promo() {
        document.querySelectorAll('.component--promo').forEach(function(element, index){
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: element,
                    start: "top bottom-=100",
                    toggleActions: "play pause pause pause",
                }
            });
            tl.to(element.querySelector('.image-cover'), {width: 0, duration: 0.4});
            tl.fromTo(element.querySelector('.content'), {opacity: 0}, {opacity: 1, duration: 0.4}, '-=0.3');
        });
    }

    photoGallery() {
        document.querySelectorAll('.component--photo-gallery').forEach(function(element, index){
            let images = element.querySelector('.image-slider'),
                captions = element.querySelector('.caption-slider'),
                rtl = ($('body[dir="rtl"]').length > 0 || ($(element).attr('dir') == 'rtl')) ? true : false;
            $(images).slick({
                centerMode: true,
                centerPadding: 0,
                slidesToShow: 1,
                speed: 600,
                asNavFor: captions,
                rtl: false,
                prevArrow: '<button class="arrow prev"><svg xmlns="http://www.w3.org/2000/svg" width="14.318" height="26.272" viewBox="0 0 14.318 26.272"><path class="a" d="M18.964,13.754,5.828,26.89,4.646,25.707,16.6,13.756,4.646,1.8,5.828.618,18.964,13.754Z" transform="translate(-4.646 -0.618)"></path></svg></button>',
                nextArrow: '<button class="arrow next"><svg xmlns="http://www.w3.org/2000/svg" width="14.318" height="26.272" viewBox="0 0 14.318 26.272"><path class="a" d="M18.964,13.754,5.828,26.89,4.646,25.707,16.6,13.756,4.646,1.8,5.828.618,18.964,13.754Z" transform="translate(-4.646 -0.618)"></path></svg></button>',
            })
            $(captions).slick({
                vertical: true,
                slidesToShow: 1,
                speed: 600,
                asNavFor: images,
                arrows: false,
                rtl: false,
            });
            $(images).find('button.next').hover(
                function() {
                    let nextSlide = rtl ? $(images).find('.slick-current').prev() : $(images).find('.slick-current').next();
                    nextSlide.addClass('hover');
                },
                function() {
                    let nextSlide = rtl ? $(images).find('.slick-current').prev() : $(images).find('.slick-current').next();
                    nextSlide.removeClass('hover');
                }
            );
            $(images).find('button.prev').hover(
                function() {
                    let prevSlide = rtl ? $(images).find('.slick-current').next() : $(images).find('.slick-current').prev();
                    prevSlide.addClass('hover');
                },
                function() {
                    let prevSlide = rtl ? $(images).find('.slick-current').next() : $(images).find('.slick-current').prev();
                    prevSlide.removeClass('hover');
                }
            );
        });
    }

    /**
     * HELPER FUNCTIONS
     */


    /**
     * Delay clicks by 3/4 sec
     */
    debounce(fn) {

        // Setup a timer
        var timeout;

        // Return a function to run debounced
        return function () {

            // Setup the arguments
            var context = this;
            var args = arguments;

            // If there's a timer, cancel it
            if (timeout) {
                window.cancelAnimationFrame(timeout);
            }

            // Setup the new requestAnimationFrame()
            timeout = window.requestAnimationFrame(function () {
                fn.apply(context, args);
            });

        }

    };

    /**
     * Load External File
     * @param {function} callback
     * @param {string} url
     */
    loadJSON(url, callback) {

        var xobj = new XMLHttpRequest();
            xobj.overrideMimeType("application/json");
            xobj.open('GET', url, true);
            xobj.onreadystatechange = function () {
              if (xobj.readyState == 4 && xobj.status == "200") {
                callback(xobj.responseText);
              }
        };
        xobj.send(null);
     }

    /**
     * Log debug message to console.
     * @param {string} message optional
     */
    logConsole(message) {
        console.log(message)
    }

    announcement() {

        if (! this.doesElementExist("#announcement-close")) return;

        var close = document.getElementById("announcement-close"),
            box = document.getElementById('announcement-banner');
        if (!localStorage.getItem('hideAnnouncement')) {
            box.classList.add('visible');
            close.addEventListener("click", function(){
                localStorage.setItem('hideAnnouncement', true);
                box.remove();
            });
        }
    }

    announcementLB() {

        if (! this.doesElementExist("#announcement-close")) return;

        document.getElementById("lightbox-close").addEventListener("click", function(){
            document.getElementById("lightbox").remove();
        });
    }
}

/**
 * Initialize Site Base
 */
// Executes after DOM is loaded (before img and css):

window.addEventListener('DOMContentLoaded', function(e) {
    try {
        new AlulaMP_Base;
    }
    catch(e){
        // console back error
        console.error(e);
    }
});

window.AlulaMP_Base = AlulaMP_Base;
